$body-background: #fafafa;
$body-color: #FDFFEA;
$link-color: #FDFFEA;

body {
  background: $body-background;
  background-image: url("/images/BackgroundPattern.png");
  color: $body-color;
  font-family: sans-serif;
  max-width: 100%;
  overflow-x: hidden;
}

h1, nav, footer {
  text-align: center;
  font-family: 'Gideon Roman', cursive;
}

h2 {
  text-align: center;
}

main {
  margin: 4rem auto;
  max-width: 60rem;
  font-family: 'Laila', sans-serif;
}

.flex {
  display: flex;
  gap: var(--gap, 1rem);
}

// Primary Header

.logo {
  width: 150px;
  height: auto;
  margin: 2rem;
}

.primary-header {
  align-items: center;
  justify-content: space-between;
}

.mobile-nav-toggle {
  display: none;
}

.primary-navigation {
  list-style: none;
  padding: 0;
  margin: 0;
  background: hsl(0 0% 20% / 0.5);
  -webkit-backdrop-filter: blur(1rem);
  backdrop-filter: blur(1rem);
  font-size: 18px;
}

// @supports (backdrop-filter: blur(1rem)) {
//   .primary-navigation {
//     background: hsl(0 0% 20% / 0.5);
//     backdrop-filter: blur(1rem);
//   }
// }

.primary-navigation a {
  text-decoration: none;
}

@media (max-width: 35em) {
  .primary-navigation {
    --gap: 2em;
    position: fixed;
    z-index: 1000;
    inset: 0 0 0 30%;
    flex-direction: column;
    padding: min(30vh, 10rem) 2em;
    transform: translateX(100%);
    transition: transform 350ms ease-in-out;
  }

  .primary-navigation[data-visible="true"] {
    transform: translateX(0%);
  }

  .mobile-nav-toggle {
    display: block;
    position: absolute;
    z-index: 9999;
    background-color: transparent;
    background-image: url("/images/icon-hamburger.svg");
    background-repeat: no-repeat;
    width: 2rem;
    height: 2rem;
    border: 0;
    top: 2rem;
    right: 2rem;
  }

  .mobile-nav-toggle[aria-expanded="true"] {
    background-image: url("/images/icon-close.svg");
  }
}

@media (min-width: 35em) {
  .primary-navigation {
    --gap: clamp(1.5rem, 5vw, 3rem);
    padding-block: 2rem;
    padding-inline: clamp(3rem, 8vw, 10rem);
  }
}

.sr-only {
  display: none;
}

.embed {
  width: 100%;
  display: flex;
}

.embed iframe {
  margin: auto;
  height: 102px;
  width: 400px;
}

#developer {
  font-size: small;
}

a, a:active, a:visited {
  color: $link-color;
  transition: ease-in-out 0.3s;
}

a:hover, a:focus {
  color: #61FDFF;
}

.container {
  background-color: hsl(0, 0%, 30%, .75);
  padding: 2em;
}